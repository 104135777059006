import React from "react"
import mapboxgl from "mapbox-gl"
import flag from "country-code-emoji"
import "./App.css"
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = "pk.eyJ1IjoidG9pbmVodWxzaG9mIiwiYSI6ImNrYjhmZTU5NzAzcm4ydHJ4a2Rla296YWQifQ.rgmnLTeSXSyNSB4kjOtR0A"
const baseUrl = "https://raw.githubusercontent.com/jpatokal/openflights/master/data/"

let airports = {}
let routes = {}
let countries = {}
let airlines = {}
let planes = {}
let previousSelectedAirports = []
let map = null

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      path: undefined,
      fromQuery: "",
      toQuery: "",
      fromAirports: [],
      toAirports: [],
      selectedAirports: [],
      averageAirplaneSpeed: 800,
      transferTime: 2.0
    }
  }

  componentDidMount() {
    fetchOpenFlights("countries")
      .then(() => fetchOpenFlights("planes"))
      .then(() => fetchOpenFlights("airlines"))
      .then(() => fetchOpenFlights("airports"))
      .then(() => fetchOpenFlights("routes"))
      .then(() => {
        map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/dark-v10",
          center: [10.0, 20.0],
          zoom: 2
        })
        const features = []
        for (let key in airports) {
          const airport = airports[key]
          features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                airport.lon,
                airport.lat
              ]
            },
            properties: {
              id: airport.id,
              name: airport.name,
              place: airport.place,
              country: airport.country,
              IATA: airport.IATA,
              ICAO: airport.ICAO,
              connections: (routes[airport.id] || []).length,
              altitude: airport.altitude
            }
          })
        }
        // map.addControl(new mapboxgl.NavigationControl())
        map.once("load", () => {
          map.addSource("airports", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: features
            }
          })
          map.addLayer({
            id: "airports",
            source: "airports",
            type: "circle",
            paint: {
              "circle-opacity": [
                "interpolate",
                ["linear"],
                ["get", "connections"],
                0, 0.5,
                500, 0.75,
              ],
              "circle-stroke-width": 1,
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["get", "connections"],
                0, 2,
                5, 4,
                10, 8,
                50, 12,
                100, 16,
                200, 20,
                500, 30,
                1000, 40,
              ],
              "circle-color": [
                "interpolate",
                ["linear"],
                ["get", "altitude"],
                -100, '#f000ff',
                0, '#0000ff',
                150, '#00ff00',
                300, '#fff000',
                1000, '#ff7000',
                5000, '#ff0000',
              ],
            }
          })
          map.addSource("selected", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          })
          map.addLayer({
            id: "selected",
            source: "selected",
            type: "line",
            paint: {
              "line-opacity": 0.75,
              "line-width": 1,
              "line-color": '#7777bb'
            }
          })
          map.addSource("connections", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          })
          map.addLayer({
            id: "connections",
            source: "connections",
            type: "line",
            paint: {
              "line-opacity": 0.5,
              "line-width": 1,
              "line-color": '#777'
            }
          })
          map.addSource("path", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          })
          map.addLayer({
            id: "path",
            source: "path",
            type: "line",
            paint: {
              // "line-cap": "round",
              // "line-join": "round",
              "line-opacity": 1,
              "line-width": 5,
              "line-color": '#fff'
            }
          })
          map.addSource("exploring", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: []
            }
          })
          map.addLayer({
            id: "exploring",
            source: "exploring",
            type: "line",
            paint: {
              // "line-cap": "round",
              // "line-join": "bevel",
              "line-opacity": 1,
              "line-width": 3,
              "line-color": '#eb34e1'
            }
          })
        })
        const routePopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          maxWidth: '200px'
        });
        map.on("mousemove", "selected", (e) => {
          setRoutePopup(e, routePopup, this.state.averageAirplaneSpeed)
        })
        map.on("mouseleave", "selected", (e) => {
          map.getCanvas().style.cursor = ""
          routePopup.remove()
        })
        map.on("mousemove", "path", (e) => {
          setRoutePopup(e, routePopup, this.state.averageAirplaneSpeed)
        })
        map.on("mouseleave", "path", (e) => {
          map.getCanvas().style.cursor = ""
          routePopup.remove()
        })
        let hoverAirport = null
        const airportPopup = new mapboxgl.Popup({
          className: "airport-popup",
          closeButton: false,
          closeOnClick: true,
          maxWidth: '200px'
        });
        map.on("mousemove", "airports", (e) => {
          if (hoverAirport) {
            if (hoverAirport === e.features[0].properties.id) { return }
          }
          map.getCanvas().style.cursor = "pointer"
          const { id, name, place, country, IATA, ICAO, connections, altitude } = e.features[0].properties
          hoverAirport = id
          const flag = getFlag(country)
          const coordinates = e.features[0].geometry.coordinates.slice()
          const IATAHTML = IATA !== "\\N" ? `<p>IATA: <b>${IATA}</b></p>` : "";
          const HTML = `<p>Name: <b>${name}</b></p>
                        <p>Place: <b>${place}</b></p>
                        <p>Country: <b>${country} ${flag}</b></p>
                        ${IATAHTML}
                        <p>ICAO: <b>${ICAO}</b></p>
                        <p>Connections: <b>${connections}</b></p>
                        <p>Alititude: <b>${altitude} feet</b></p>`
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          airportPopup
            .setLngLat(coordinates)
            .setHTML(HTML)
            .addTo(map)
          map.getSource("connections").setData({
            type: "FeatureCollection",
            features: (routes[hoverAirport] || []).filter(route => airports[route.from] && airports[route.to]).map((route) => ({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [airports[route.from].lon + (Math.abs(airports[route.from].lon - airports[route.to].lon) > 180 ? (airports[route.from].lon > airports[route.to].lon ? -360 : 360) : 0), airports[route.from].lat],
                  [airports[route.to].lon, airports[route.to].lat]
                ]
              }
            }))
          })
        })
        map.on("mouseleave", "airports", (e) => {
          map.getCanvas().style.cursor = ""
          airportPopup.remove()
          hoverAirport = null
          map.getSource("connections").setData({
            type: "FeatureCollection",
            features: []
          })
        })
        map.on("click", (e) => {
          previousSelectedAirports = this.state.selectedAirports
          this.setState({
            path: undefined,
            fromAirports: [],
            toAirports: [],
            selectedAirports: []
          })
          map.getSource("selected").setData({
            type: "FeatureCollection",
            features: []
          })
          map.getSource("path").setData({
            type: "FeatureCollection",
            features: []
          })
        })
        map.on("click", "airports", (e) => {
          this.setState({
            selectedAirports: previousSelectedAirports.concat([e.features[0].properties.id])
          })
          if (this.state.selectedAirports.length === 2) {
            const path = AStar(this.state.selectedAirports[0], this.state.selectedAirports[1], this.state.averageAirplaneSpeed, this.state.transferTime)
            this.setState({
              path: path,
              selectedAirports: []
            })
          }
          map.getSource("selected").setData({
            type: "FeatureCollection",
            features: this.state.selectedAirports.flatMap((selectedAirport) => (routes[selectedAirport] || []).filter(route => airports[route.from] && airports[route.to]).map((route) => ({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: [
                  [airports[route.from].lon + (Math.abs(airports[route.from].lon - airports[route.to].lon) > 180 ? (airports[route.from].lon > airports[route.to].lon ? -360 : 360) : 0), airports[route.from].lat],
                  [airports[route.to].lon, airports[route.to].lat]
                ]
              },
              properties: {
                from: route.from,
                fromIATA: route.fromIATA,
                to: route.to,
                toIATA: route.toIATA,
                airlineCode: route.airline,
                airlineID: route.airlineID,
                distance: route.distance,
                stops: route.stops,
                planeCodes: route.planes//.split(' ')
              }
            })))
          })
        })
      })
  }

  search(query) {
    const results = Object.keys(airports).filter(key => {
      return airports[key].place.toLowerCase().includes(query.toLowerCase()) || airports[key].IATA.toLowerCase().includes(query.toLowerCase()) || airports[key].name.toLowerCase().includes(query.toLowerCase())
    }).slice(0, 10)
    return results
  }

  render() {
    const { path, fromQuery, toQuery, fromAirports, toAirports, selectedAirports, averageAirplaneSpeed, transferTime } = this.state
    return (
      <div className="App">
        <div id="map"/>
        <Path path={path} speed={averageAirplaneSpeed} transferTime={transferTime} />
        <div className="welcome">
          <h4><b>Flight Visualizer</b></h4>
          {/* <p>Click on two airports to find the shortest route between them using the A* algorithm!</p> */}
          <p>Find the shortest route between two airports.<br></br>Click on airports or search them below!</p>
          <div className="search-box">
            <input 
              type="text"
              className="search-bar"
              onFocus={e => this.setState({
                fromQuery: ""
              })}
              placeholder="From"
              onChange={e => {
                const fromAirports = e.target.value === "" ? [] : this.search(e.target.value)
                this.setState({
                  fromQuery: e.target.value,
                  fromAirports: fromAirports
                })
              }}
              value={fromQuery}
            />
            {fromAirports.map(airportID => {
              const airport = airports[airportID]
              const flag = getFlag(airport.country)
              const IATAHTML = `(${airport.IATA === "\\N" ? airport.ICAO : airport.IATA})`
              return (
                <div className="result" onClick={e => {
                  if (selectedAirports.length >= 1) {
                    const path = AStar(parseInt(airportID), parseInt(selectedAirports[0]), averageAirplaneSpeed, transferTime)
                    this.setState({
                      path: path,
                      selectedAirports: [],
                      fromAirports: [],
                      fromQuery: `${flag} ${airport.place} (${airport.IATA || airport.ICAO})`
                    })
                  } else {
                    this.setState({
                      selectedAirports: [airportID],
                      fromAirports: [],
                      fromQuery: `${flag} ${airport.place} (${airport.IATA || airport.ICAO})`
                    })
                  }
                }}>
                  {getFlag(airport.country)} {airport.place} <b>{IATAHTML}</b>
                </div>
              )
            })}
          </div>
          <div className="search-box">
            <input 
              type="text"
              className="search-bar"
              onFocus={e => this.setState({
                toQuery: ""
              })}
              placeholder="To"
              onChange={e => {
                const toAirports = e.target.value === "" ? [] : this.search(e.target.value)
                this.setState({
                  toQuery: e.target.value,
                  toAirports: toAirports
                })
              }}
              value={toQuery}
            />
            {toAirports.map(airportID => {
              const airport = airports[airportID]
              const IATAHTML = `(${airport.IATA === "\\N" ? airport.ICAO : airport.IATA})`
              const flag = getFlag(airport.country)
              return (
                <div className="result" onClick={e => {
                  if (selectedAirports.length >= 1) {
                    const path = AStar(parseInt(selectedAirports[0]), parseInt(airportID), averageAirplaneSpeed, transferTime)
                    this.setState({
                      path: path,
                      selectedAirports: [],
                      toAirports: [],
                      toQuery: `${flag} ${airport.place} (${airport.IATA || airport.ICAO})`
                    })
                  } else {
                    this.setState({
                      selectedAirports: [airportID],
                      toAirports: [],
                      toQuery: `${flag} ${airport.place} (${airport.IATA || airport.ICAO})`
                    })
                  }
                }}>
                {getFlag(airport.country)} {airport.place} <b>{IATAHTML}</b>
                </div>
              )
            })}
          </div>
          <div className="slider-container">
            <p>Average speed { averageAirplaneSpeed }km/h</p>
            <input
              className="slider"
              type="range" 
              min="400" 
              max="1200" 
              value={averageAirplaneSpeed} 
              id="myRange"
              onChange={e => {
                this.setState({
                  averageAirplaneSpeed: parseFloat(e.target.value)
                })
              }}
            />
          </div>
          <div className="slider-container">
            <p>Transfer time { transferTime }h</p>
            <input
              className="slider"
              type="range" 
              min="0" 
              max="10"
              step="0.5"
              value={transferTime} 
              id="myRange"
              onChange={e => {
                this.setState({
                  transferTime: parseFloat(e.target.value)
                })
              }}
            />
          </div>
        </div>
        <div className="banner">
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/ToineHulshof/FlightVisualizer">💻Source Code </a>
          Toine Hulshof 2020
        </div>
      </div>
    )
  }
}

function Path(props) {
  const path = props.path
  const speed = props.speed
  const transferTime = props.transferTime
  if (!path) return ""
  if (path.length === 0) { return(
    <div className = "path">
      <h4>No Path Found</h4>
    </div>
  ) }
  return (
    <div className = "path">
    <h4>Shortest Path</h4>
    <Total path={path} speed={speed} transferTime={transferTime} />
    {path.map(route => {
      const plane = planes[route.planes.split(' ')[0].replace(/[\r\n]+/gm,"")]
      return <table className="path-element">
        <tr>
          <td>Places:</td>
          <td className="right-table-cell"><b>{airports[route.from].place} - {airports[route.to].place}</b></td>
        </tr>
        <tr>
          <td>Route:</td>
          <td className="right-table-cell">{getFlag(airports[route.from].country)} <b>{route.fromIATA} - {route.toIATA}</b> {getFlag(airports[route.to].country)}</td>
        </tr>
        <tr>
          <td>Info:</td>
          <td className="right-table-cell"><b>{Math.round(route.distance)}km - {distanceToTime(route.distance, speed)}</b></td>
        </tr>
        <tr>
          <td>Airline:</td>
          <td className="right-table-cell"><b>{airlines[route.airlineID].name}</b> {getFlag(airlines[route.airlineID].country)}</td>
        </tr>
        <Airplane plane={plane} />
      </table>
    })}
  </div>
  );
}

function Airplane(props) {
  const plane = props.plane
  if (!plane) return ""
  return (
    <tr>
      <td>Airplane:</td>
      <td className="right-table-cell"><b>{plane.name}</b></td>
    </tr>
  );
}

function Total(props) {
  const { path, speed, transferTime } = props
  if (path.length === 0) { return "" }
  const from = path[0].from
  const to = path[path.length - 1].to
  return (
    <table className="total path-element">
      <tr>
        <td>Places:</td>
        <td className="right-table-cell"><b>{airports[from].place} - {airports[to].place}</b></td>
      </tr>
      <tr>
        <td>Route:</td>
        <td className="right-table-cell">{getFlag(airports[from].country)} <b>{airports[from].IATA} - {airports[to].IATA}</b> {getFlag(airports[to].country)}</td>
      </tr>
      <tr>
        <td>Info:</td>
        <td className="right-table-cell"><b>{Math.round(path.reduce((p, c) => p + c.distance, 0))}km - {distanceToTime(path.reduce((p, c) => p + c.distance, 0), speed)}</b></td>
      </tr>
      <tr>
        <td>Stops:</td>
        <td className="right-table-cell"><b>{path.length - 1} stops - {(path.length - 1) * transferTime}h</b></td>
      </tr>
      <tr>
        <td>Total:</td>
        <td className="right-table-cell"><b>{hoursToTime(((path.length - 1) * transferTime) + (path.reduce((p, c) => p + c.distance, 0) / speed))}</b></td>
      </tr>
    </table>
  )
}

function setRoutePopup(e, routePopup, speed) {
  map.getCanvas().style.cursor = "pointer"
  const { from, fromIATA, to, toIATA, airlineCode, airlineID, distance, stops, planeCodes } = e.features[0].properties
  // const flag = getFlag(country)
  const coordinates = e.lngLat
  const roundedDistance = Math.round(distance)
  const fromAirport = airports[from]
  const fromFlag = fromAirport ? getFlag(fromAirport.country) : ""
  const fromAirportHTML = fromAirport ? `<b>${fromAirport.place}</b>` : "";
  const toAirport = airports[to]
  const toFlag = toAirport ? getFlag(toAirport.country) : ""
  const toAirportHTML = toAirport ? `<b>${toAirport.place}</b>` : "";
  const airline = airlines[airlineID]
  const airlineFlag = airline ? getFlag(airline.country) : ""
  const plane = planes[planeCodes.split(' ')[0]]
  const time = distanceToTime(roundedDistance, speed)
  const planeHTML = plane ? `<p>Plane: <b>${plane.name}</b></p>` : ""
  const airlineHTML = airline ? `<p>Airline: <b>${airline.name} ${airlineFlag}</b></p>` : ""
  const HTML = `<p>From: ${fromAirportHTML} <b>(${fromIATA}) ${fromFlag}</b></p>
                <p>To: ${toAirportHTML} <b>(${toIATA}) ${toFlag}</b></p>
                ${airlineHTML}
                ${planeHTML}
                <p>Distance: <b>${roundedDistance}km (${time})</b></p>`
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }
  routePopup
    .setLngLat(coordinates)
    .setHTML(HTML)
    .addTo(map)
}

function distanceToTime(distance, speed) {
  const totalTime = distance / speed
  return hoursToTime(totalTime)
}

function hoursToTime(time) {
  const hours = Math.floor(time)
  const minutes = Math.round((time % 1) * 60)
  return `${hours}h${minutes}m`
}

async function fetchOpenFlights(name) {
  await fetch(baseUrl + name + ".dat")
    .then(res => res.text())
    .then(text => {
      switch (name) {
        case "airports":
          text.split('\n').forEach((line) => {
            const fields = line.split(',')
            const id = fields[0]
            airports[id] = {
              id: parseInt(id),
              name: (fields[1] || "").replace(/"/g,""),
              place: (fields[2] || "").replace(/"/g,""),
              country: (fields[3] || "").replace(/"/g,""),
              IATA: (fields[4] || "").replace(/"/g,""),
              ICAO: (fields[5] || "").replace(/"/g,""),
              lat: parseFloat(fields[6]),
              lon: parseFloat(fields[7]),
              altitude: parseInt(fields[8]),
              timezone: fields[9],
              dst: fields[10]
            }
          })
          break
        case "routes":
          routes = text.split('\n').reduce((current, route) => {
            const fields = route.split(',')
            const id = fields[3]
            const from = parseInt(fields[3])
            const to = parseInt(fields[5])
            const fromAirport = airports[from]
            const toAirport = airports[to]
            let distance = Infinity
            if (fromAirport && toAirport) {
              distance = getDistanceFromLatLonInKm(fromAirport.lat, fromAirport.lon, toAirport.lat, toAirport.lon)
            }
            current[id] = (current[id] || []).concat({
              from: from,
              fromIATA: fields[2],
              to: to,
              toIATA: fields[4],
              airline: fields[0],
              airlineID: parseInt(fields[1]),
              distance: distance,
              stops: fields[7],
              planes: fields[8]//.split(' ')
            })
            return current
          }, {})
          break
        case "countries":
          text.split('\n').forEach((line) => {
            const fields = line.split(',')
            countries[(fields[0] || "").replace(/"/g,"")] = {
              name: (fields[0] || "").replace(/"/g,""),
              code: (fields[1] || "").replace(/"/g,"")
            }
          })
          break
        case "planes":
          text.split('\n').forEach((line) => {
            const fields = line.split(',').map(field => field.replace(/"|\\r/g,""))
            planes[fields[1]] = {
              name: fields[0],
              IATA: fields[1],
              ICAO: fields[2]
            }
          })
          break
        case "airlines":
          text.split('\n').forEach((line) => {
            const fields = line.split(',')
            const id = parseInt(fields[0])
            airlines[id] = {
              id: id,
              name: (fields[1] || "").replace(/"/g,""),
              alias: fields[2],
              IATA: fields[3],
              ICAO: fields[4],
              callsign: fields[5],
              country: (fields[6] || "").replace(/"/g,""),
              active: fields[7] === "Y"
            }
          })
          break
        default:
          break
      }
    })
}

function AStar(from, to, speed, transferTime) {
  console.log("AStar", from, to, speed, transferTime)
  const averageAirplaneSpeed = speed //km/h
  const fCost = route => gCost(route) + hCost(route)
  const gCost = route => distances[route.from] + (route.distance / averageAirplaneSpeed) + (route.to === to ? 0 : transferTime)
  const hCost = route => {
    const fromAirport = airports[route.to]
    const toAirport = airports[to]
    let remainingFlightTime = Infinity
    if (fromAirport && toAirport) {
      remainingFlightTime = getDistanceFromLatLonInKm(fromAirport.lat, fromAirport.lon, toAirport.lat, toAirport.lon) / averageAirplaneSpeed // hours 
    }
    return remainingFlightTime
  }
  let states = {}
  let distances = {}
  let fCosts = {}
  let parents = {}
  for (let key in airports) {
    distances[key] = Infinity
    fCosts[key] = Infinity
  }
  distances[from] = 0
  states[from] = "open"
  const keys = Object.keys(distances).flatMap(key => parseInt(key))
  for (let i = 0; i < keys.length - 1; i++) {
    const open = keys.filter(key => states[key] === "open")
    if (open.length === 0) { return [] } //No path found
    const closest = open.reduce((previous, current) => {
      return fCosts[previous] < fCosts[current] ? previous : current
    })
    setTimeout(() => {
      if (closest === to) {
        const path = backTrace(to)
        map.getSource("exploring").setData({
          type: "FeatureCollection",
          features: []
        })
        map.getSource("path").setData({
          type: "FeatureCollection",
          features: path.map((route) => ({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [
                [airports[route.from].lon + (Math.abs(airports[route.from].lon - airports[route.to].lon) > 180 ? (airports[route.from].lon > airports[route.to].lon ? -360 : 360) : 0), airports[route.from].lat],
                [airports[route.to].lon, airports[route.to].lat]
              ]
            },
            properties: {
              from: route.from,
              fromIATA: route.fromIATA,
              to: route.to,
              toIATA: route.toIATA,
              airlineCode: route.airline,
              airlineID: route.airlineID,
              distance: route.distance,
              stops: route.stops,
              planeCodes: route.planes//.split(' ')
            }
          }))
        })
      } else {
        setMapData(closest)
      }
    }, 20 * i)
    if (closest === to) {
      return backTrace(to)// Found shortest path!
    }
    states[closest] = "closed"
    for (const route of (routes[closest] || [])) {
      if (states[route.to] === "closed") { continue }
      if (gCost(route) < distances[route.to] || states[route.to] !== "open") {
        parents[route.to] = route
        distances[route.to] = gCost(route)
        fCosts[route.to] = fCost(route)
        states[route.to] = "open"
      }
    }
  }
  return [] // No path found

  function backTrace(airport) {
    let path = []
    let child = airport
    while (child !== from) {
      path.push(parents[child])
      child = parents[child].from
    }
    return path.reverse()
  }

  function setMapData(currentAirport) {
    map.getSource("exploring").setData({
      type: "FeatureCollection",
      features: backTrace(currentAirport).filter(route => airports[route.from] && airports[route.to]).map((route) => ({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [airports[route.from].lon + (Math.abs(airports[route.from].lon - airports[route.to].lon) > 180 ? (airports[route.from].lon > airports[route.to].lon ? -360 : 360) : 0), airports[route.from].lat],
            [airports[route.to].lon, airports[route.to].lat]
          ]
        }
      }))
    })
  }
}

function BFS() {
  const from = this.state.selectedAirports[0]
  const to = this.state.selectedAirports[1]
  let visited = {}
  for (let key in airports) {
    visited[key] = false
  }
  visited[from] = true
  let queue = [{current: from, path: [from]}]
  while (queue.length !== 0) {
    const { current, path } = queue.shift()
    if (current === to) { return path }
    for (const route of (routes[current] || [])) {
      if (!visited[route.to]) {
        visited[route.to] = true
        queue.push({current: route.to, path: path.concat([route.to])})
      }
    }
  }
  return []
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return isNaN(d) ? Infinity : d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

function getFlag(countryName) {
  const country = countries[(countryName || "")]
  if (country && country.code && country.code !== "\\N" && country.code.length === 2) {
    return flag(country.code)
  }
  return ""
}

export default App